<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4>
      <v-flex xs12 sm6 md8 text-left px2>
        <span class="itemHeading">
          Edit
          <span v-if="programs.isCottage">Accomodation</span>
          <span v-else>Program</span>
        </span>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm3 md2 pa-2 v-if="packageRates.length > 0">
        <router-link :to="'/packageRates/' + this.id">
          <v-btn block color="#68D389" :ripple="false" depressed>
            <span style="color: white"> Edit Package </span>
          </v-btn>
        </router-link>
      </v-flex>
      <v-flex xs12 sm3 md2 pa-2 v-if="slots.length > 0">
        <router-link :to="'/slots/' + this.id">
          <v-btn block color="#68D389" :ripple="false" depressed>
            <span style="color: white"> Edit Slot </span>
          </v-btn>
        </router-link>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pt-5>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md4>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="programs.progName"
                  outlined
                  label="Title of the Program "
                  required
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4 pl-md-4>
                <v-select
                    class="textField2"
                    :items="categories"
                    label="Category"
                    dense
                    v-model="category"
                    item-value="_id"
                    item-text="name"
                    outlined
                  ></v-select>
              </v-flex>

              <v-flex xs12 md4 pl-md-4>
                <v-select
                    class="textField2"
                    :items="subcategories"
                    label="Sub Category"
                    dense
                    v-model="programs.subCategory"
                    item-value="_id"
                    item-text="name"
                    outlined
                  ></v-select>
              </v-flex>
              <v-flex xs12 md8>
                <v-textarea
                  class="textField2"
                  dense
                  rows="3"
                  v-model="programs.caption"
                  outlined
                  label="Caption of the Program"
                  required
                ></v-textarea>
              </v-flex>
              <v-flex xs12 md6 pr-2>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="programs.lat"
                  outlined
                  label="Latitude of the Program"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="programs.long"
                  outlined
                  label="Longitude of the Program"
                  required
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 xs12>
            <ImageComp
              @stepper="winStepper"
              :photos="details.allPhotos"
              :pageId="details._id"
              :height="'400'"
              :width="'600'"
              :type="'program'"
              :heading="'Upload Images'"
            />
          </v-flex>
          <v-flex xs12 pt-5>
            <CoverImageComp
              @stepper="winStepper"
              :singleImage="details.coverImage"
              :pageId="details._id"
              :height="'2118'"
              :width="'8001'"
              :heading="'Upload Cover Image'"
              :componentType="'coverImage'"
            />
          </v-flex>

          <v-flex xs12 md12>
            <v-layout wrap justify-center>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1"
                  >Please Add the Program Description</span
                >
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.description"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 pt-5 text-left>
                <span class="textField1">Please Add the Program Notes</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.notes"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5>
                <span class="textField1">Please Add the Program Rules</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.rules"
                ></vue-editor>
              </v-flex>
              <v-flex xs12 md12 text-left pt-5 v-if="programs.isCottage">
                <span class="textField1">Please Add the Program Schedule</span>
                <vue-editor
                  class="textField2 pt-1"
                  v-model="programs.cottage.schedule"
                ></vue-editor>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="programs.isCottage">
            <v-combobox
              v-model="programs.cottage.activities"
              :items="items"
              chips
              clearable
              label="Activities"
              multiple
              solo
              class="pt-3"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex xs12 md12 pt-4>
            <v-layout wrap justify-center>
              <v-flex xs12 md12 pt-4>
                <v-layout wrap justify-center text-left>
                  <v-flex xs12 md4>
                    <!-- <span class="textField2"
                      >Please specify Starting Location</span
                    > -->

                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.startPoint"
                      outlined
                      label=" Please specify Starting Location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <!-- <span class="textField2"
                      >Please specify Ending Location</span
                    > -->
                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.endPoint"
                      outlined
                      label="Please specify Ending Location"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <!-- <span class="textField2"
                      >Please specify Reporting Time</span
                    > -->

                    <v-text-field
                      class="textField2"
                      dense
                      v-model="programs.reportingTime"
                      outlined
                      label="Please specify Reporting Time"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-start text-left>
                  <v-flex xs12 md4>
                    <!-- <span class="textField2"
                      >Please specify Duration (in Hrs)</span
                    > -->

                    <v-text-field
                      class="textField2"
                      type="number"
                      dense
                      v-model="programs.duration"
                      outlined
                      label="Please specify Duration (in Hrs)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-md-3 px-lg-3 px-xl-3>
                    <!-- <span class="textField2"
                      >Please specify Online Percent (in %)</span
                    > -->

                    <v-text-field
                      class="textField2"
                      dense
                      type="text"
                      v-model="programs.onlinePercent"
                      outlined
                      label="Please specify Online Percent (in %)"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <!-- <span class="textField2">Please specify Minimum Age</span> -->
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.minAge"
                      outlined
                      label="Please specify Minimum Age"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4>
                    <!-- <span class="textField2">Please specify Maximum Age</span> -->
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.maxAge"
                      outlined
                      label="Please specify Maximum Age"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 px-lg-3 px-xl-3 v-if="!programs.isCottage">
                    <!-- <span class="textField2">Please specify Minimum Guest</span> -->
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.minGuest"
                      outlined
                      label="Please specify Minimum Guest"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md4 v-if="!programs.isCottage">
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.maxGuest"
                      outlined
                      label="Please specify Maximum Guest"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md4
                    px-md-3
                    px-lg-3
                    px-xl-3
                    v-if="programs.isCottage"
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.guestPerRoom"
                      outlined
                      label="Please specify maximum guest per room"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 md12>
                <v-layout wrap justify-start text-left> </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 md6 text-left>
                <span class="textField1"
                  >Please Select the types of guests you want to include.</span
                >
                
                <v-layout wrap justify-center pt-5 text-left>
                  <v-flex>
                    <v-checkbox
                      v-model="programs.bookingAvailability.indian"
                      label="Indian"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="programs.bookingAvailability.foreigner"
                      label="Foreigner"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex  >
                    <v-checkbox
                      v-model="programs.bookingAvailability.children"
                      label="Children"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex >
                    <v-checkbox
                      v-model="programs.bookingAvailability.foreigneChildren"
                      label="Foreign Children"
                    ></v-checkbox>
                  </v-flex>


                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12 text-left>
                <span style="font-family: poppinssemibold; font-size: 18px"
                  >Extra Person
                  <span style="font-family: poppinslight; font-size: 14px"
                    >(Please specify maximum guest stay as addition for each
                    section)</span
                  ></span
                >
              </v-flex>
              <v-flex xs12 md12 pt-3>
                <v-layout wrap justify-start text-left>
                  <v-flex xs12 md3 pr-3>
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.maxExtraGuestCount"
                      outlined
                      label=" maximum extra guest count"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 md3 v-if="programs.bookingAvailability.indian">
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.maxExtraIndianCount"
                      outlined
                      label="Maximum indian adult"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="programs.bookingAvailability.foreigner"
                    xs12
                    md3
                    :px-md-3="
                      (!programs.bookingAvailability.children &&
                        programs.bookingAvailability.foreigner &&
                        !programs.bookingAvailability.indian) ||
                      (programs.bookingAvailability.children &&
                        programs.bookingAvailability.foreigner &&
                        !programs.bookingAvailability.indian)
                        ? false
                        : true
                    "
                    px-xs-0
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.maxExtraForeignerCount"
                      outlined
                      label="Maximum Foreign adult "
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="programs.bookingAvailability.children"
                    xs12
                    md3
                    :pl-3="
                      !programs.bookingAvailability.children ||
                      (programs.bookingAvailability.children &&
                        programs.bookingAvailability.indian &&
                        !programs.bookingAvailability.foreigner) ||
                      (programs.bookingAvailability.children &&
                        programs.bookingAvailability.foreigner &&
                        !programs.bookingAvailability.indian)
                        ? true
                        : false
                    "
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.maxExtraChildrenCount"
                      outlined
                      label="Maximum Childern"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    v-if="programs.bookingAvailability.foreigneChildren"
                    xs12
                    md3
                    :pl-3="
                      !programs.bookingAvailability.foreigneChildren ||
                      (programs.bookingAvailability.foreigneChildren &&
                        programs.bookingAvailability.indian &&
                        !programs.bookingAvailability.foreigner) ||
                      (programs.bookingAvailability.foreigneChildren &&
                        programs.bookingAvailability.foreigner &&
                        !programs.bookingAvailability.indian)
                        ? true
                        : false
                    "
                  >
                    <v-text-field
                      class="textField2"
                      dense
                      type="number"
                      v-model="programs.cottage.maxExtraForeignChildrenCount"
                      outlined
                      label="Maximum Foreign Childern"
                      required
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  dark
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  Save Changes
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/CommonComponents/multipleImages";
import CoverImageComp from "@/components/CommonComponents/singleImage";
export default {
  props: ["id"],
  components: {
    ImageComp,
    CoverImageComp,
  },
  data() {
    return {
      items:[],
      subCategory: null,
      category:null,
      programs: {
        progName: null,
        subCategory:null,
        description: null,
        caption: null,
        startPoint: null,
        endPoint: null,
        duration: null,
        // isCottage:false,
        onlinePercent: null,
        minGuest: null,
        maxGuest: null,
        maxAge: null,
        
        minAge: null,
        reportingTime: null,
        rules: null,
        notes: null,
        addons: null,
        programid: null,
        category: null,
        cottage: {
          guestPerRoom: null,
          activities: [],
          schedule: null,
          maxExtraGuestCount: null,
          maxExtraIndianCount: null,
          maxExtraForeignerCount: null,
          maxExtraChildrenCount: null,
          maxExtraForeignChildrenCount:null,
        },
        bookingAvailability: {
          indian: true,
          foreigner: true,
          children: true,
          foreigneChildren:true,

        },
      },
      details: [],
      programId: null,
      imageArray: [],
      formData: new FormData(),
      selectedFiles: [],
      formDataCover: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      subcategories:[],
      packageRate: [],
      packageRates: [],
      slots: [],
      categories: [],
      pgmType: null,
      coverImageFile: null,
      imageFiles: [],
    };
  },
  watch:{
    category()
    {
      this.getSubCategories()
    }
  },
  mounted() {
    this.getCategories();
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        this.imageFiles.splice(window_data.removalItem, 1);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "imageDeletion") {
        if (window_data.response.status) this.getData();
      } else if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
        console.log("cov",this.coverImageFile)
      }
    },
    
    getSubCategories() {
        axios({
          method: "GET",
          url: "/subcategory/list",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            category: this.category,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.subcategories = response.data.data;
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            console.log(err);
          });
      },
    getCategories() {
      axios({
        method: "GET",
        url: "/category/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.categories = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    validateInput() {
      if (!this.programs.progName) {
        this.msg = "Please Provide Program name";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.rules) {
        this.msg = "Please Provide Program rules";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.startPoint) {
        this.msg = "Please Provide Start point";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.endPoint) {
        this.msg = "Please Provide End ponit";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.notes) {
        this.msg = "Please Provide Program notes";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.reportingTime) {
        this.msg = "Please Provide Reporting time";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.duration) {
        this.msg = "Please Provide Program duration";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.onlinePercent) {
        this.msg = "Please Provide Online percent";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.minAge) {
        this.msg = "Please Provide Minimum age";
        this.showSnackBar = true;
        return;
      } else if (!this.programs.minAge) {
        this.msg = "Please Provide Maximum age";
        this.showSnackBar = true;
        return;
      // } else if (!this.imageFiles.length>0 && this.details.allPhotos.length>0) {
      //   this.msg = "Please Upload Image";
      //   this.showSnackBar = true;
      //   return;
      // } else if (!this.coverImageFile && !this.details.coverImage) {
      //   this.msg = "Please Upload Cover Image";
      //   this.showSnackBar = true;
      //   return;
      } else {
        this.editProgram();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/programme/adminget",
        params: {
          id: this.id,
          //   date: new Date(),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.details = response.data.data;
          this.slots = response.data.slots;
          this.packageRates = response.data.package;
          this.programs = this.details;
          this.category=this.programs.category
          // this.programs.subCategory=response.data.data.subCategory._id;
          if (this.programs.isCottage) this.pgmType = "Cottage";
          else this.pgmType = "Programs";
          this.pricing = response.data.package;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editProgram() {
      this.programs.programid = this.id;
      this.programs.bookingAvailability.foreignStudent=this.programs.bookingAvailability.foreigneChildren
      this.programs.cottage.maxExtraForeignChildrenCount=parseInt(this.programs.cottage.maxExtraForeignChildrenCount)
      this.programs.category=this.category;
      if (!this.programs.bookingAvailability.indian)
        this.programs.maxExtraIndianCount = 0;
      if (!this.programs.bookingAvailability.foreigner)
        this.programs.maxExtraForeignerCount = 0;
      if (!this.programs.bookingAvailability.children)
        this.programs.maxExtraChildrenCount = 0;
        if (!this.programs.bookingAvailability.foreigneChildren)
        this.programs.maxExtraForeignChildrenCount = 0;
      axios({
        method: "post",
        url: "/edit/program",
        data: this.programs,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.imageArray.length > 0) {
              for (var i = 0; i < this.imageFiles.length; i++)
                this.formData.append("images", this.imageFiles[i]);
              this.uploadMainImages(this.id);
            } else if (this.coverImageFile) {
              this.uploadCoverImages(this.id);
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.$router.push("/Admin/" + this.pgmType);
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },

    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("programme", item);
      axios({
        method: "POST",
        url: "/programme/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/Admin/" + this.pgmType);
          } else {
            this.msg = "Can't Upload Images";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadCoverImages(item) {
      this.appLoading = true;
      this.formDataCover.append("programme", item);
      this.formDataCover.append("image", this.coverImageFile);
      axios({
        method: "POST",
        url: "/programme/UploadCoverImage",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/Admin/" + this.pgmType);
          } else {
            this.msg = "Can't Upload Images";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>